export const environment = {
  production: true,
  fullStoryId: null,
  apiurl: 'https://demo.rideroundtrip.com/api/v1/admin',
  apiv3url: 'https://demo.rideroundtrip.com/api/v3',
  appurl: 'https://demo.rideroundtrip.com/',
  googleApiKey: 'AIzaSyDMvZ9qzO5JhH8S3pIueTyPBs8SimMQ0KY',
  launchDarklyClientApiKey: '6115343f5caa4e239332c1a4',
  auth0ClientId: 'wmRe7fSmRIhitDblfmWx1byP2L59iLLM',
  auth0Domain: 'login-demo.rideroundtrip.com',
  adminCreation: 'EVERYONE'
};
